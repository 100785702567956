import useWebsiteId from '@hooks/useWebsiteId'
import {useQuery} from 'apollo-hooks'

import {
  getInitialAddressDataAddressProvider,
  getInitialAddressDataAddressProviderVariables
} from '../__generated__/getInitialAddressDataAddressProvider'
import {getPreferencesQuery} from '../queries'

export default function useOrderOptionPreferences() {
  const websiteId = useWebsiteId()
  const {data, loading, error} = useQuery<
    getInitialAddressDataAddressProvider,
    getInitialAddressDataAddressProviderVariables
  >({
    query: getPreferencesQuery,
    variables: {websiteId},
    partial: true
  })

  return {
    data,
    loading,
    error
  }
}
