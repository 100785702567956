import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import useWebsiteId from '@hooks/useWebsiteId'

import {
  setUserStoreIdPreferencesAddressProvider,
  setUserStoreIdPreferencesAddressProviderVariables
} from '../__generated__/setUserStoreIdPreferencesAddressProvider'

import {UseUpdateParams} from './types'
import useOrderOptionPreferences from './useOrderOptionPreferences'

export const setStoreIdMutation = gql`
  mutation setUserStoreIdPreferencesAddressProviderHook($websiteId: ID, $storeId: ID) {
    setUserPreferences(websiteId: $websiteId, storeId: $storeId) {
      _id
      storeId
      store {
        _id
        name
        schedule {
          itsOpenNow: itsAvailableAt
        }
        acceptServeSchedulingOrders
        schedulingOrdersDays
        currentPreparationDuration
        currentExtraDeliveryCost
        currentDeliveryDuration
        isOverloaded
        address {
          address
          placeId
          addressLine2
          location
        }
      }
    }
  }
`

export default function useUpdateStoreId({onSuccess, onError}: UseUpdateParams) {
  const websiteId = useWebsiteId()
  const [updateStoreIdHook] = useMutation<
    setUserStoreIdPreferencesAddressProvider,
    setUserStoreIdPreferencesAddressProviderVariables
  >(setStoreIdMutation)
  const {data} = useOrderOptionPreferences()

  const updateStoreId = useCallback(
    async (storeId: string) => {
      try {
        await updateStoreIdHook({
          variables: {storeId, websiteId},
          refetchQueries: ['getMyOrderPreferences']
          // the store is missing to be able to do an optimisticResponse
        })
        onSuccess?.()
      } catch (error) {
        onError?.(error)
      }
    },
    [data?.preferences?._id, onError, onSuccess, updateStoreIdHook, websiteId]
  )

  return updateStoreId
}
