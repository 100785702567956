import React from 'react'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function SecondLineInput({value, onChange}) {
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})

  return (
    <>
      <Text
        className="os-input-text"
        placeholder={t('addressLine2.placeholder')}
        value={value ?? ''}
        onChange={onChange}
      />
      <div className={styles.example_label}>
        <span>{t('addressLine2.example')}</span>
      </div>
    </>
  )
}
