import React, {useCallback, useContext, useMemo} from 'react'
import ReactSelect, {ActionMeta} from 'react-select'
import AddressContext from '@helpers/addresses/AddressesContext'
import {ClearType, Option} from '@helpers/addresses/models'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import {useTranslation} from 'next-i18next'

export interface AddressSelectorProps {
  value: any
  onChange: (value: any) => void
  selectedCityName?: string
  passProps: {
    setPlaceId: (placeId: string) => void
  }
  isModal?: boolean
}

export default function AddressSearchSelector({
  value: valueProps,
  onChange: onChangeProps,
  passProps: {setPlaceId},
  isModal
}: AddressSelectorProps) {
  const {
    options,
    filter,
    setFilter,
    isLoadingOptions,
    loadingGeolocation,
    clear,
    sessionId,
    placeId
  } = useContext(AddressContext)
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})

  const value = useMemo(() => {
    if (!valueProps) return null
    const firstElement = Array.isArray(options) && options.length ? options[0] : null
    const selectedElement = options.find(option => option.value === valueProps)
    return selectedElement ?? firstElement
  }, [options, valueProps])

  const onChange = useCallback(
    (option: Option, actionMeta: ActionMeta<Option>) => {
      if (actionMeta.action === 'select-option') {
        sendPosthogData(EVENTS.selected.addressOption, {
          placeId: option.value,
          filter,
          sessionId,
          previousPlaceId: placeId
        })
        // onChange calls setPlaceId
        onChangeProps(option.value)
      } else if (actionMeta.action === 'clear' || actionMeta.action === 'deselect-option') {
        clear(ClearType.PLACE_ID)
      }
    },
    [clear, filter, onChangeProps, placeId, sessionId, setPlaceId]
  )

  return (
    <ReactSelect
      key={'loaded'}
      styles={{
        menu: provided => ({
          ...provided,
          zIndex: 1001
        }),
        input: (provided, state) => {
          const {color, ...styles} = provided
          return {
            ...styles
          }
        }
      }}
      classNamePrefix="orion-select"
      placeholder={t('address.placeholder')}
      options={options}
      isClearable={true}
      inputValue={filter ?? ''}
      onInputChange={setFilter}
      isLoading={isLoadingOptions || loadingGeolocation}
      noOptionsMessage={({inputValue}) =>
        inputValue
          ? 'No hemos podido encontrar tu dirección. Puedes usar una ubicación de referencia y luego corregir la posición en el mapa'
          : 'Escribe tu dirección con calle y número para que aparezcan los resultados'
      }
      value={value}
      onChange={onChange}
      filterOption={null}
    />
  )
}
