import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import {DeliveryTypes} from '@helpers/addresses/models'
import useWebsiteId from '@hooks/useWebsiteId'

import {UseUpdateParams} from './types'
import useOrderOptionPreferences from './useOrderOptionPreferences'

export const setDeliveryTypeMutation = gql`
  mutation setPreferencesAddressProviderHook($websiteId: ID, $deliveryType: ID) {
    setUserPreferences(websiteId: $websiteId, deliveryType: $deliveryType) {
      _id
      deliveryType
    }
  }
`

export default function useUpdateDeliveryType({onSuccess, onError}: UseUpdateParams) {
  const websiteId = useWebsiteId()
  const [updateDeliveryTypeHook] = useMutation(setDeliveryTypeMutation)
  const {data} = useOrderOptionPreferences()

  const updateDeliveryType = useCallback(
    async (deliveryType: DeliveryTypes) => {
      try {
        await updateDeliveryTypeHook({
          variables: {deliveryType, websiteId},
          optimisticResponse: {
            __typename: 'Mutation',
            setUserPreferences: {
              __typename: 'Preference',
              _id: data?.preferences?._id,
              deliveryType
            }
          }
        })
        onSuccess?.()
      } catch (error) {
        onError?.(error)
      }
    },
    [data?.preferences?._id, onError, onSuccess, updateDeliveryTypeHook, websiteId]
  )

  return updateDeliveryType
}
