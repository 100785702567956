import Button from '@components/Button'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'

import styles from './styles.module.css'

const ConditionalWrapper = ({condition, wrapper, children}) =>
  condition ? wrapper(children) : children

export default function SubmitButton({
  disabled,
  onClick,
  message
}: {
  disabled: boolean
  onClick: () => void
  message: string
}) {
  return (
    <ConditionalWrapper
      condition={disabled}
      wrapper={children => (
        <Tooltip className={styles.tooltip} content="Completa todos los campos para continuar">
          {children}
        </Tooltip>
      )}>
      <div className={styles.formSubmitButton}>
        <Button primary disabled={disabled} fullWidth onClick={onClick}>
          {message}
        </Button>
      </div>
    </ConditionalWrapper>
  )
}
