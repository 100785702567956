import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {MdLocationPin} from 'react-icons/md'
import {MdClose} from 'react-icons/md'
import ReactSelect from 'react-select'
import Button from '@components/Button'
import ClientSuspense from '@components/ClientSuspense'
import DeliveryType from '@components/fields/DeliveryType'
import Loading from '@components/Loading'
import Modal from '@components/Modal'
import AddressContext from '@helpers/addresses/AddressesContext'
import {AddressFormType, ClearType, CustomStore, DeliveryTypes} from '@helpers/addresses/models'
import isServerSide from '@helpers/misc/isServerSide'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useCountryCode from '@hooks/useCountryCode'
import useMessage from '@hooks/useMessage'
import useShouldRequireCity from '@hooks/useShouldRequireCity'
import useWebsiteId from '@hooks/useWebsiteId'
import useAddressOutOfBounds from '@page-components/Checkout/Checkout/Delivery/Address/AddressOutOfBounds/useAddressOutOfBounds'
import CitySelector, {
  CityOption
} from '@page-components/Checkout/Checkout/Delivery/Address/Update/CitySelector'
import OutOfBoundsAlert from '@page-components/Checkout/Checkout/Delivery/Address/Update/OutOfBoundsAlert'
import SelectedStore from '@page-components/Checkout/Checkout/Delivery/Address/Update/SelectedStore'
import StoreName from '@page-components/Checkout/Checkout/Delivery/StoreName'
import StoreMessage from '@page-components/Order/Options/UpdateBasicOptions/StoreMessage'
import TableName from '@page-components/Order/Options/UpdateBasicOptions/TableName'
import Time from '@page-components/Order/Options/UpdateBasicOptions/Time'
import useOrderOptionPreferences from '@providers/Addresses/hooks/useOrderOptionPreferences'
import useUpdateDeliveryType from '@providers/Addresses/hooks/useUpdateDeliveryType'
import useUpdateStoreId from '@providers/Addresses/hooks/useUpdateStoreId'
import {
  getMyOrderPreferencesQueryAddressProvider,
  getWebsiteStoresQuery
} from '@providers/Addresses/queries'
import {useQuery} from 'apollo-hooks'
import classnames from 'classnames'
import {useTranslation} from 'next-i18next'

import AddressSearchSelector from './AddressSearchSelector'
import LeafletMap from './LeafletMap'
import SecondLineInput from './SecondLineInput'
import SubmitButton from './SubmitButton'

import styles from './styles.module.css'

const MOBILE_WIDTH_THRESHOLD = 650

interface AddressFormProps {
  type: AddressFormType
  isModal?: boolean
  closeModal: () => void
  // only needed for type deliveryTypes
  onSelectDeliveryType?: (value: string, isTheOnlyValue?: boolean) => void
  websiteId: string

  setPreferences?: (preferences: any) => void
  isFromHeader?: boolean
  showStoreName?: boolean
  layout?: string
}

const ADDRESS_COUNTRIES = ['CL', 'PE', 'CO']

enum MapPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}

export const AddressForm = ({
  type: typeSuggestion,
  isModal,
  websiteId,
  closeModal,
  onSelectDeliveryType,
  showStoreName
}: AddressFormProps) => {
  const {
    placeId,
    setPlaceId,
    selectedCity,
    setSelectedCity,
    location,
    setLocation,
    clear,
    addressLine2,
    setAddressLine2,
    loadingGeolocation,
    setLoadingGeolocation,
    sessionId
  } = useContext(AddressContext)
  const {t} = useTranslation('website', {keyPrefix: 'order.options.updateBasicOptions'})
  const countryCode = useCountryCode()
  const showMessage = useMessage()
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true)

  const {data: orderPreferencesData, loading} = useOrderOptionPreferences()
  const deliveryType = orderPreferencesData?.preferences?.deliveryType
  const updateDeliveryType = useUpdateDeliveryType({})
  const storeId = orderPreferencesData?.preferences?.storeId
  const updateStoreId = useUpdateStoreId({})

  const {
    data: {stores: queryStores}
  } = useQuery<{stores?: {items: CustomStore[]}}, {websiteId: string}>({
    query: getWebsiteStoresQuery,
    variables: {websiteId}
  })

  const {data: dataPrefs} = useQuery({
    query: getMyOrderPreferencesQueryAddressProvider,
    variables: {websiteId, storeId},
    omit: isServerSide() || !websiteId,
    fetchPolicy: 'cache-first',
    partial: true
  })

  const stores = queryStores?.items ?? []
  const store: CustomStore | null = stores.find(store => store.value === storeId)

  const preferences: any = dataPrefs?.preferences ?? {}
  const isAddressCountry = ADDRESS_COUNTRIES.includes(countryCode.toUpperCase())
  const mapPosition = isAddressCountry ? MapPosition.BOTTOM : MapPosition.TOP

  const containerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const handleWindowSizeChange = () => {
    if (containerRef?.current) setWidth(containerRef.current.offsetWidth)
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const displayOutOfBoundsMsg = useAddressOutOfBounds({placeId, storeId})

  let type: AddressFormType = typeSuggestion

  if (
    (typeSuggestion === AddressFormType.FormAndMap && width < MOBILE_WIDTH_THRESHOLD) ||
    typeSuggestion === AddressFormType.Form
  ) {
    if (mapPosition === MapPosition.TOP) type = AddressFormType.FormWithTopMap
    if (mapPosition === MapPosition.BOTTOM) type = AddressFormType.FormWithBottomMap
  }

  function handleLocation(position) {
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    setLocation({lat, lng})
    setLoadingGeolocation(false)
  }

  function errorHandler(err) {
    setLoadingGeolocation(false)
    if (err.code == 1) {
      sendPosthogData(EVENTS.error.locationAccessDenied, {sessionId})
      showMessage(new Error(t('geolocation.errors.accessDenied')))
    } else if (err.code == 2) {
      sendPosthogData(EVENTS.error.locationUnavailable, {sessionId})
      showMessage(new Error(t('geolocation.errors.unavailable')))
    }
  }

  function getLocation() {
    sendPosthogData(EVENTS.clicked.useLocation, {sessionId})
    setLoadingGeolocation(true)
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      const options = {
        timeout: 60000,
        // This forces the device to give better results IF IT CAN, but it can take longer or consume more battery.
        enableHighAccuracy: true,
        // To prevent the use of cached locations
        maximumAge: 0
      }
      navigator.geolocation.getCurrentPosition(handleLocation, errorHandler, options)
    } else {
      setLoadingGeolocation(false)
      showMessage(new Error('Lo sentimos, tu navegador no soporta geolocalización'))
    }
  }

  const shouldRequireCity = useShouldRequireCity()
  const shouldShowTitle = type !== AddressFormType.DeliveryTypes
  const shouldShowMap =
    (deliveryType === DeliveryTypes.DELIVERY && placeId) ||
    (deliveryType !== DeliveryTypes.DELIVERY && storeId)
  const shouldShowSideMap = shouldShowMap && type === AddressFormType.FormAndMap
  const shouldShowHighMap = shouldShowMap && type === AddressFormType.FormWithTopMap
  const shouldShowLowMap = shouldShowMap && type === AddressFormType.FormWithBottomMap
  const shouldShowForm = shouldShowTitle

  const mapLocation =
    deliveryType === DeliveryTypes.DELIVERY ? (location as any) : store?.address?.location
  const isDraggingMarkerEnabled = deliveryType === DeliveryTypes.DELIVERY

  const isSubmitDisabled = deliveryType === DeliveryTypes.DELIVERY ? !placeId : !storeId

  const formMap = useMemo(() => {
    return (
      (shouldShowHighMap || shouldShowLowMap) &&
      mapLocation && (
        <LeafletMap
          location={mapLocation}
          isDraggingMarkerEnabled={isDraggingMarkerEnabled}
          setLocation={location => {
            setShowSubmitButton(true)
            setLocation(location)
          }}
          containerClass={classnames(styles.embeddedMapContainer, {
            [styles.embeddedMapContainerThin]:
              width < MOBILE_WIDTH_THRESHOLD && typeSuggestion !== AddressFormType.FormAndMap
          })}
        />
      )
    )
  }, [
    isDraggingMarkerEnabled,
    mapLocation,
    setLocation,
    shouldShowHighMap,
    shouldShowLowMap,
    typeSuggestion,
    width
  ])

  const sideMap = useMemo(() => {
    return (
      shouldShowSideMap &&
      mapLocation && (
        <div className={styles.mapSection}>
          <LeafletMap
            location={mapLocation}
            isDraggingMarkerEnabled={isDraggingMarkerEnabled}
            setLocation={location => {
              setShowSubmitButton(true)
              setLocation(location)
            }}
          />
        </div>
      )
    )
  }, [isDraggingMarkerEnabled, mapLocation, setLocation, shouldShowSideMap])

  const renderStore = () => {
    if (!deliveryType) {
      return <StoreMessage preferences={preferences} />
    }

    if (!preferences || loading) return

    const filteredStores = stores?.filter(store => {
      if (!store?.address?.placeId) {
        return false
      }
      if (deliveryType === DeliveryTypes.GO && store.acceptGo) {
        return true
      }
      if (deliveryType === DeliveryTypes.SERVE && store.acceptServe) {
        return true
      }
      return false
    })

    const hasOptions = filteredStores?.length
    if (!hasOptions) {
      updateStoreId(null)
    }

    const isStoreIdIncludedInOptions = filteredStores?.map(option => option.value).includes(storeId)
    if ((hasOptions && !storeId) || (hasOptions && storeId && !isStoreIdIncludedInOptions)) {
      updateStoreId(filteredStores[0].value)
    }

    const value = filteredStores.find(store => store.value === storeId)

    return (
      <div key={`storeSelector${deliveryType}`}>
        {shouldShowHighMap && deliveryType !== DeliveryTypes.DELIVERY && formMap}
        <div className="label" style={{marginTop: 0}}>
          {t('labelChooseStore')}
        </div>
        <ReactSelect<{label: string; value: string}>
          value={value}
          onChange={option => {
            setShowSubmitButton(true)
            updateStoreId(option.value)
          }}
          classNamePrefix="orion-select"
          placeholder={t('labelSelectStore')}
          options={filteredStores}
          filterOption={null}
          menuPosition={isModal ? 'fixed' : undefined}
          styles={{
            control: provided => ({
              ...provided,
              padding: '4px 0'
            }),
            ...(isModal
              ? {
                  menuPortal: provided => ({
                    ...provided,
                    zIndex: 1001
                  })
                }
              : {
                  menu: provided => ({
                    ...provided,
                    zIndex: 1001
                  })
                })
          }}
        />
      </div>
    )
  }

  const renderTime = () => {
    const {time} = preferences
    if (!store) return
    if (deliveryType === DeliveryTypes.SERVE && !store.acceptServeSchedulingOrders) return
    if (deliveryType === DeliveryTypes.SERVE && time === 'now') return

    return (
      <>
        <Time
          selectorProps={{
            menuPosition: 'fixed',
            styles: {
              menuPortal: provided => ({
                ...provided,
                zIndex: 1001
              })
            }
          }}
          onChange={() => setShowSubmitButton(true)}
          preferences={preferences}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          showDirections={!!placeId && placeId !== 'no-deliver'}
        />
        {shouldShowLowMap && deliveryType !== DeliveryTypes.DELIVERY && formMap}
      </>
    )
  }

  const renderTableName = () => {
    const {time} = preferences
    if (!store) return
    if (deliveryType !== DeliveryTypes.SERVE) return
    if (time !== 'now') return

    return <TableName preferences={preferences} />
  }

  const renderDelivery = () => {
    return (
      <>
        {shouldShowForm && (
          <div>
            <div className={styles.geolocationButton}>
              <Button primary onClick={getLocation} loading={loadingGeolocation} fullWidth>
                <MdLocationPin size={20} />
                {t('sharePosition')}
              </Button>
            </div>
            {shouldShowHighMap && formMap}
            {shouldRequireCity && !placeId && (
              <div className={styles.inputContainer}>
                <CitySelector
                  city={selectedCity ? {label: selectedCity, value: selectedCity} : null}
                  changeSelectedCity={(option: CityOption) => {
                    if (!option) return clear(ClearType.SELECTED_CITY)
                    clear(ClearType.PLACE_ID)
                    setSelectedCity(option.value)
                  }}
                />
              </div>
            )}
            {(!shouldRequireCity || selectedCity || placeId) && (
              <div className={styles.inputContainer}>
                <AddressSearchSelector
                  value={placeId}
                  onChange={value => {
                    setShowSubmitButton(true)
                    setPlaceId(value)
                  }}
                  passProps={{
                    setPlaceId
                  }}
                  isModal={isModal}
                />
                {!loading && (displayOutOfBoundsMsg || (placeId && !location)) ? (
                  <OutOfBoundsAlert
                    message={displayOutOfBoundsMsg || 'No llegamos a esa dirección'}
                  />
                ) : placeId ? (
                  <SelectedStore preferences={preferences} />
                ) : (
                  <div className={styles.example_label}>
                    <span>{t('address.example')}</span>
                  </div>
                )}
              </div>
            )}
            {(!shouldRequireCity || placeId) && (
              <div className={styles.inputContainer}>
                <SecondLineInput
                  value={addressLine2}
                  onChange={value => {
                    setShowSubmitButton(true)
                    setAddressLine2(value)
                  }}
                />
              </div>
            )}
            {placeId && renderTime()}
            {shouldShowLowMap && formMap}
          </div>
        )}
      </>
    )
  }

  if (type === AddressFormType.DeliveryTypes) {
    return (
      <div style={{width: 340}}>
        <DeliveryType
          value={null}
          onChange={onSelectDeliveryType}
          light={true}
          passProps={{light: true}}
        />
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className={classnames(styles.formContainer, styles.container, {
        [styles.modalFormContainer]: type === AddressFormType.FormAndMap,
        [styles.backgroundColor]: isModal
      })}>
      {sideMap}
      <div
        className={classnames(styles.formSection, {
          [styles.formSectionThin]:
            width < MOBILE_WIDTH_THRESHOLD && typeSuggestion !== AddressFormType.FormAndMap
        })}>
        <div>
          {shouldShowTitle && <h2 className={styles.formTitle}>{t('title')}</h2>}
          <DeliveryType
            value={deliveryType}
            onChange={value => {
              setShowSubmitButton(true)
              updateDeliveryType(value)
              if (value !== DeliveryTypes.DELIVERY && !storeId && stores?.length) {
                updateStoreId(stores[0].value)
              }
            }}
          />
          {deliveryType === DeliveryTypes.DELIVERY && renderDelivery()}
          {deliveryType !== DeliveryTypes.DELIVERY && renderStore()}
          {deliveryType !== DeliveryTypes.DELIVERY && renderTime()}
          {deliveryType !== DeliveryTypes.DELIVERY && renderTableName()}
          {(placeId || storeId) && (
            <StoreName preferences={preferences} website={{showStoreName}} isOnMenu />
          )}
        </div>
        {showSubmitButton && (
          <SubmitButton
            disabled={isSubmitDisabled}
            message={deliveryType === DeliveryTypes.DELIVERY ? t('useAddress') : t('useStore')}
            onClick={() => {
              sendPosthogData(EVENTS.clicked.addressSubmitButton)
              setShowSubmitButton(false)
              closeModal()
              showMessage('Dirección guardada')
            }}
          />
        )}
      </div>
    </div>
  )
}
interface CreateAddressProps {
  closeModal: () => void
}

export default function CreateAddress({closeModal}: CreateAddressProps) {
  const websiteId = useWebsiteId()

  return (
    <div>
      <Modal close={closeModal} customClass={styles.modal} avoidElementsClass="submodal">
        <div className={styles.closeIconSection}>
          <div className={styles.closeIconContainer}>
            <div className={styles.closeIcon} onClick={closeModal}>
              <MdClose size={30} />
            </div>
          </div>
        </div>
        <ClientSuspense
          fallback={
            <div className={styles.modalLoading}>
              <Loading />
            </div>
          }>
          <AddressForm
            websiteId={websiteId}
            closeModal={closeModal}
            type={AddressFormType.FormAndMap}
            isModal={true}
          />
        </ClientSuspense>
      </Modal>
    </div>
  )
}
